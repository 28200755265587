@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

@font-face {
  font-family: password;
  font-style: normal;
  font-weight: 400;
  src: url("https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf");
}

input.key {
  font-family: password, sans-serif;
  width: 100px;
  height: 20px;
}

@layer components {
  /* Customize scrollbar styles */
  ::-webkit-scrollbar {
    width: 0.5vw; /* Adjust width */
    height: 0.25vh; /* Adjust height */
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Adjust track background color */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9c9c9c; /* Adjust thumb background color */
    border-radius: 0.5vw; /* Adjust thumb border-radius */
  }
}
