.presets {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 4px;
}

.presets button {
  margin: 0 4px 4px 0;
  font-size: 12px !important;
  padding: 4px 8px !important;
  border-radius: 16px !important;
  line-height: 1.5 !important;
  height: auto !important;

  /* background-color: #14213d !important; */
  color: #14213d !important;
}

.presets button:hover {
  /* background-color: #fca311 !important; */
  color: #14213d !important;
  border-color: #14213d !important;
}

.range-picker {
  border-radius: 4px !important;
  border: 1px solid #c4c4c4 !important;
  box-shadow: none !important;
  min-height: 56px !important;
  min-width: 16rem !important;
  width: 284px !important;
}

/* hover effect matching mui component */
.range-picker:hover {
  border-color: #14213d !important;
}

.ant-picker .ant-picker-input > input {
  text-align: left !important;
}

.range-picker:focus {
  border-color: #14213d !important;
}

.ant-picker .ant-picker-suffix {
  color: #757575 !important;
}

.ant-picker .ant-picker-range-separator {
  color: #757575 !important;
}

.ant-picker .ant-picker-active-bar {
  background-color: #14213d !important;
}

.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #14213d !important;
  color: #fff !important;
}

.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #14213d !important;
  color: #fff !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #14213d !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > .ant-picker-panel:nth-child(2) {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  z-index: 100 !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.ant-picker-panel-container .ant-picker-panels {
  width: 100% !important;
}

.ant-picker-dropdown {
  position: fixed !important;
}

@media (width <= 600px) {
  .range-picker {
    width: 100% !important;
  }
}
